@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-v4020-ThinItalic.eot');
  src: url('fonts/Raleway-v4020-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/Raleway-v4020-ThinItalic.woff') format('woff'),
  url('fonts/Raleway-v4020-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-v4020-ExtraBold.eot');
  src: url('fonts/Raleway-v4020-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('fonts/Raleway-v4020-ExtraBold.woff') format('woff'),
  url('fonts/Raleway-v4020-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-v4020-Bold.eot');
  src: url('fonts/Raleway-v4020-Bold.eot?#iefix') format('embedded-opentype'),
  url('fonts/Raleway-v4020-Bold.woff') format('woff'),
  url('fonts/Raleway-v4020-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-v4020-BoldItalic.eot');
  src: url('fonts/Raleway-v4020-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/Raleway-v4020-BoldItalic.woff') format('woff'),
  url('fonts/Raleway-v4020-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-v4020-Medium.eot');
  src: url('fonts/Raleway-v4020-Medium.eot?#iefix') format('embedded-opentype'),
  url('fonts/Raleway-v4020-Medium.woff') format('woff'),
  url('fonts/Raleway-v4020-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-v4020-SemiBold.eot');
  src: url('fonts/Raleway-v4020-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('fonts/Raleway-v4020-SemiBold.woff') format('woff'),
  url('fonts/Raleway-v4020-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-v4020-Black.eot');
  src: url('fonts/Raleway-v4020-Black.eot?#iefix') format('embedded-opentype'),
  url('fonts/Raleway-v4020-Black.woff') format('woff'),
  url('fonts/Raleway-v4020-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-v4020-MediumItalic.eot');
  src: url('fonts/Raleway-v4020-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/Raleway-v4020-MediumItalic.woff') format('woff'),
  url('fonts/Raleway-v4020-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-v4020-ExtraLightItalic.eot');
  src: url('fonts/Raleway-v4020-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/Raleway-v4020-ExtraLightItalic.woff') format('woff'),
  url('fonts/Raleway-v4020-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-v4020-Regular.eot');
  src: url('fonts/Raleway-v4020-Regular.eot?#iefix') format('embedded-opentype'),
  url('fonts/Raleway-v4020-Regular.woff') format('woff'),
  url('fonts/Raleway-v4020-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-v4020-SemiBoldItalic.eot');
  src: url('fonts/Raleway-v4020-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/Raleway-v4020-SemiBoldItalic.woff') format('woff'),
  url('fonts/Raleway-v4020-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-v4020-BlackItalic.eot');
  src: url('fonts/Raleway-v4020-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/Raleway-v4020-BlackItalic.woff') format('woff'),
  url('fonts/Raleway-v4020-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-v4020-ExtraBoldItalic.eot');
  src: url('fonts/Raleway-v4020-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/Raleway-v4020-ExtraBoldItalic.woff') format('woff'),
  url('fonts/Raleway-v4020-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-v4020-Light.eot');
  src: url('fonts/Raleway-v4020-Light.eot?#iefix') format('embedded-opentype'),
  url('fonts/Raleway-v4020-Light.woff') format('woff'),
  url('fonts/Raleway-v4020-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-v4020-LightItalic.eot');
  src: url('fonts/Raleway-v4020-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('fonts/Raleway-v4020-LightItalic.woff') format('woff'),
  url('fonts/Raleway-v4020-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-v4020-ExtraLight.eot');
  src: url('fonts/Raleway-v4020-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('fonts/Raleway-v4020-ExtraLight.woff') format('woff'),
  url('fonts/Raleway-v4020-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-v4020-Italic.eot');
  src: url('fonts/Raleway-v4020-Italic.eot?#iefix') format('embedded-opentype'),
  url('fonts/Raleway-v4020-Italic.woff') format('woff'),
  url('fonts/Raleway-v4020-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('fonts/Raleway-v4020-Thin.eot');
  src: url('fonts/Raleway-v4020-Thin.eot?#iefix') format('embedded-opentype'),
  url('fonts/Raleway-v4020-Thin.woff') format('woff'),
  url('fonts/Raleway-v4020-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
